<template>
    <div class="content-area p-4">
        <p>仓颉科技（以下简称“我们”）在向您提供仓颉科技代备案服务（以下简称“本服务”）时，会按照《仓颉科技代备案服务协议》以及本声明的约定收集、使用、存储、对外提供及保护您的个人信息；超出以上约定收集您的用户信息的，我们会另行向您说明信息收集的范围与目的，并征得您的同意后方收集提供本服务所必要的您的信息；如您选择不提供前述信息，将会影响到您使用本服务，但不会影响您使用仓颉科技网站基本功能和其他产品。</p>
        <br />
        <p>在使用本服务前，请您务必仔细阅读并透彻理解本声明的全部内容，在确认充分理解并同意后方使用本服务。一旦您开始使用本服务，将被视为对本声明内容的接受和认可。</p>
        <br />
        <br />
        <p>一、定义</p>
        <br />
        <p>1.1.仓颉科技服务号：指仓颉科技微信服务号（名称为：广州仓颉科技有限公司）。</p>
        <br />
        <p>1.2.仓颉科技：仓颉科技代备案服务的提供方，包括广州仓颉科技有限公司、上海观安信息技术股份有限公司、深圳观安信息技术有限公司。</p>
        <br />
        <p>1.3.用户信息：是指您注册仓颉科技网站账户，使用仓颉科技代备案服务以及仓颉科技为了向您提供服务，您提交的或者仓颉科技收集的用户信息，具体详见本声明第二条所述的信息。</p>
        <br />
        <br />
        <p>二、我们如何收集您的用户信息</p>
        <br />
        <p>我们会收集您在使用本服务过程中主动提供或因使用服务而产生的信息：</p>
        <br />
        <p>2.1.设备信息：我们会根据您在软件安装及使用中授予的具体权限，接收并记录您所使用的设备相关信息（例如设备型号、操作系统版本、设备设置、唯一设备标识符等软硬件特征信息）、设备所在位置相关信息（例如IP地址、GPS位置以及能够提供相关信息的Wi-Fi接入点、蓝牙和基站等传感器信息）。</p>
        <br />
        <p>2.2.备案信息：当您使用本服务时，我们会收集您主动填写/上传的备案信息以及配合我们真实性核验过程中所提供的信息。包括：</p>
        <br />
        <p>2.2.1.个人信息：主体负责人及网站负责人的姓名、身份证件号码、身份证件原件照片、人脸图像或视频、手机号码、固定电话、联系地址、电子邮件、职业状况、手持身份证件照片等；</p>
        <br />
        <p>2.2.2.单位信息：单位名称、单位证件号码、住所、通信地址、投资者或上级主管、单位证件原件照片、手持单位证件照片等；</p>
        <br />
        <p>2.2.3.小程序信息：小程序名称、小程序备案号、小程序服务内容、小程序语言、前置或专项审批内容类型、小程序ID、小程序备注；</p>
        <br />
        <p>2.2.4.其他信息：与您签署的各项协议、备案核查的电话录音及视频等。</p>
        <br />
        <p>2.3.5.您应对您的使用本服务过程中提交的备案信息的真实性、准确性负责，因您的提供的备案信息不真实、不准确而造成的全部结果及责任均由您自行承担。</p>
        <br />
        <br />
        <p>三、我们如何使用您的用户信息</p>
        <br />
        <p>为了遵守国家法律法规及监管要求，以及向您提供服务及提升服务质量，我们会在以下情形中使用您提交以及我们收集的用户信息：</p>
        <br />
        <p>3.1. 为了向您提供服务，我们会向您发送信息、通知或与您进行业务沟通，包括但不限于为保证服务完成所必须的验证码、使用服务时所必要的推送通知、进行备案核查时的电话/邮件通知等；</p>
        <br />
        <p>3.3.2.为了代理您完成小程序备案申请的提交，我们在取得您明确授权同意的情况下，可能会代您对部分备案信息进行修改/补充。</p>
        <br />
        <p>3.3.3.为了维护、改进服务，向您提供更符合您个性化需求的信息展示，我们可能将您使用本服务提供的用户信息与来自其他项服务的信息结合起来，做出特征模型并进行用户画像，向您展示、推送信息和可能的商业广告，包括但不限于关于仓颉科技产品的新闻以及市场活动及优惠促销信息、仓颉科技合作第三方的推广信息，或其他您可能感兴趣的内容。如果您不想接收我们给您发送的商业性电子信息，您可通过短信提示回复退订或根据信息中提供的退订方式予以退订；</p>
        <br />
        <p>3.3.4. 我们可能以用户信息统计数据为基础，设计、开发、推广全新的产品及服务；我们会对我们的服务使用情况进行统计，并可能会与公众或第三方分享这些统计信息，但这些统计信息不包含您的任何身份识别信息；</p>
        <br />
        <p>3.3.5. 为提高您使用我们及我们关联公司、合作伙伴提供服务的安全性，确保操作环境安全与识别账号异常状态，保护您或其他用户或公众的人身财产安全免遭侵害，更好地预防钓鱼网站、欺诈、网络漏洞、计算机病毒、网络攻击、网络侵入等安全风险，更准确地识别违反法律法规或仓颉科技相关协议、规则的情况，我们可能使用您使用本服务过程中收集的用户信息、并整合设备信息、有关网络日志以及我们关联公司、合作伙伴合法分享的信息，来判断您账户及交易风险、进行身份验证、安全事件的检测及防范，并依法采取必要的记录、审计、分析、处置措施；</p>
        <br />
        <p>3.3.6. 如超出收集用户信息时所声称的目的，或者在超出具有直接或合理关联的范围使用用户信息前，我们会再次向您告知并征得您的明示同意。</p>
        <br />
        <br />
        <p>四、我们如何共享、转让、公开披露您的用户信息</p>
        <br />
        <p>4.1. 共享</p>
        <br />
        <p>我们不会与其他组织和个人共享您的用户信息，但以下情况除外：</p>
        <br />
        <p>4.1.1. 按照法律相关规定，申请备案时需要提供给备案审核机关通信管理局及工信部的信息、证件及其他材料；</p>
        <br />
        <p>4.1.2.在获取明确同意的情况下共享：获得您的明确同意后，我们会与其他方共享您的用户信息；</p>
        <br />
        <p>4.1.3.在法定情形下的共享：我们可能会根据法律法规规定、诉讼、仲裁解决需要，或按行政、司法机关依法提出的要求，对外共享您的用户信息；</p>
        <br />
        <p>4.1.4.为了协助解决争议，某些情况下只有共享您的用户信息，才能处理您与他人的纠纷或争议，例如，第三方向我们提起侵权投诉后，您承认投诉成立、或您无理由不做回应、或我们通过谨慎判断认为投诉成立后，投诉方为后续维权要求我们提供您的信息的；</p>
        <br />
        <p>4.1.5.与授权合作伙伴共享：我们可能委托受信赖的合作伙伴来提供服务，因此我们可能会与合作伙伴共享您的某些用户信息，以提供更好的客户服务和优化用户体验。我们仅会出于合法、正当、必要、特定、明确的目的共享您的用户信息，并且只会共享提供服务所必要的用户信息。我们的合作伙伴无权将共享的用户信息用于任何其他用途。对我们与之共享用户信息的公司、组织和个人，我们会与其签署严格的保密协议以及信息保护约定，要求他们按照我们的说明、隐私权政策以及其他任何相关的保密和安全措施来处理用户信息。</p>
        <br />
        <p>4.2. 转让</p>
        <br />
        <p>我们不会将您的用户信息转让给任何公司、组织和个人，但以下情况除外：</p>
        <br />
        <p>4.2.1. 在获取明确同意的情况下转让：获得您的明确同意后，我们会向其他方转让您的用户信息；</p>
        <br />
        <p>4.2.2. 在仓颉科技与其他法律主体者发生合并、收购或破产清算情形，或其他涉及合并、收购或破产清算情形时，如涉及到用户信息转让，广州仓颉科技有限公司会要求新的持有您用户信息的公司、组织继续受本政策的约束，否则我们将要求该公司、组织和个人重新向您征求授权同意。</p>
        <br />
        <p>4.3. 公开披露</p>
        <br />
        <p>我们仅会在以下情况下，公开披露您的用户信息：</p>
        <br />
        <p>4.3.1.获得您明确同意或基于您的主动选择，我们可能会公开披露您的用户信息；</p>
        <br />
        <p>4.3.2. 或为保护仓颉科技平台及其关联公司用户或公众的人身财产安全免遭侵害，我们可能依据适用的法律或仓颉科技平台相关协议、规则披露关于您的用户信息。</p>
        <br />
        <p>4.4. 共享、转让、公开披露用户信息时事先征得授权同意的例外</p>
        <br />
        <p>以下情形中，共享、转让、公开披露您的用户信息无需事先征得您的授权同意：</p>
        <br />
        <p>4.4.1. 与国家安全、国防安全有关的；</p>
        <br />
        <p>4.4.2. 与公共安全、公共卫生、重大公共利益有关的；</p>
        <br />
        <p>4.4.3. 与犯罪侦查、起诉、审判和判决执行等有关的；</p>
        <br />
        <p>4.4.4. 出于维护您或其他个人的生命、财产等重大合法权益但又很难得到本人同意的；</p>
        <br />
        <p>4.4.5. 您自行向社会公众公开的个人信息；</p>
        <br />
        <p>4.4.6.从合法公开披露的信息中收集个人信息的，如合法的新闻报道、政府信息公开等渠道。</p>
    </div>
</template>

<script>
export default {
    mounted() {
        // 进入页面后平滑至顶部
        window.scrollTo({
            top: 0,
            behavior: 'smooth', // 使用平滑滚动效果
        })
    },
}
</script>

<style>
</style>