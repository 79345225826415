<template>
    <div class="bg-box">
        <div class="page-title">备案信息详情</div>
        <div class="info-area">
            <div class="area-title">主体信息</div>
            <div class="info-list">
                <div class="list">
                    <div class="title">证件类型</div>
                    <div class="content">{{detailsInfo.certificate_type}}</div>
                </div>
                <div class="list">
                    <div class="title">证件编号</div>
                    <div class="content">{{detailsInfo.certificate_number}}</div>
                </div>
                <div class="list">
                    <div class="title">备案号</div>
                    <div class="content">{{detailsInfo.icp_number}}</div>
                </div>
            </div>
            <div class="info-list">
                <div class="list">
                    <div class="title">所属区域</div>
                    <div class="content">{{detailsInfo.province_name + detailsInfo.city_name + detailsInfo.area_name}}</div>
                </div>
                <div class="list">
                    <div class="title">证件住所</div>
                    <div class="content">{{detailsInfo.certificate_address}}</div>
                </div>
            </div>
            <div class="info-list">
                <div class="list">
                    <div class="title">法定代表人姓名</div>
                    <div class="content">{{detailsInfo.corp_name}}</div>
                </div>
                <div class="list">
                    <div class="title">法定代表人证件号码</div>
                    <div class="content">{{detailsInfo.corp_id_number}}</div>
                </div>
                <div class="list">
                    <div class="title">通讯地址</div>
                    <div class="content">{{detailsInfo.contact_address}}</div>
                </div>
            </div>
            <div class="info-list">
                <div class="list">
                    <div class="title">通讯地址其他备注</div>
                    <div class="content">{{detailsInfo.contact_remark}}</div>
                </div>
            </div>
        </div>

        <div class="info-area">
            <div class="area-title">{{details.app_name}}小程序信息</div>
            <div class="info-list">
                <div class="list">
                    <div class="title">小程序服务内容</div>
                    <div class="content">{{detailsInfo.app_service}}</div>
                </div>
                <div class="list">
                    <div class="title">服务类型</div>
                    <div class="content">{{detailsInfo.app_type1 + detailsInfo.app_type2}}</div>
                </div>
            </div>
            <div class="info-list">
                <div class="list">
                    <div class="title">小程序语言</div>
                    <div class="content">{{detailsInfo.app_lang}}</div>
                </div>
                <div class="list">
                    <div class="title">前置或专项审批内容类型</div>
                    <div class="content">{{detailsInfo.app_content_type}}</div>
                </div>
                <div class="list">
                    <div class="title">小程序ID</div>
                    <div class="content">{{detailsInfo.app_id}}</div>
                </div>
                <div class="list">
                    <div class="title">小程序备注</div>
                    <div class="content">{{detailsInfo.app_remark}}</div>
                </div>
            </div>
        </div>

        <div class="info-area">
            <div class="area-title">{{details.app_name}}小程序负责人信息</div>
            <div class="info-list">
                <div class="list">
                    <div class="title">小程序负责人姓名</div>
                    <div class="content">{{detailsInfo.app_corp_name}}</div>
                </div>
                <div class="list">
                    <div class="title">小程序负责人证件类型</div>
                    <div class="content">{{detailsInfo.app_corp_id_type}}</div>
                </div>
                <div class="list">
                    <div class="title">小程序负责人证件号</div>
                    <div class="content">{{detailsInfo.app_corp_id_number}}</div>
                </div>
                <div class="list">
                    <div class="title">证件有效期起始日期</div>
                    <div class="content">{{detailsInfo.app_corp_id_begindate}}</div>
                </div>
                <div class="list">
                    <div class="title">证件有效期终止日期</div>
                    <div class="content">{{detailsInfo.app_corp_id_enddate}}</div>
                </div>
            </div>
            <div class="info-list">
                <div class="list">
                    <div class="title">小程序负责人联系电话</div>
                    <div class="content">{{detailsInfo.app_corp_phone}}</div>
                </div>
                <div class="list">
                    <div class="title">小程序负责人应急联系电话</div>
                    <div class="content">{{detailsInfo.app_corp_phone_back}}</div>
                </div>
                <div class="list">
                    <div class="title">小程序负责人电子邮箱</div>
                    <div class="content">{{detailsInfo.app_corp_email}}</div>
                </div>
            </div>
            <div class="info-list">
                <div class="list">
                    <div class="title">小程序其他备注</div>
                    <div class="content">{{detailsInfo.app_remark}}</div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            orderId: '',
            details: '',
            detailsInfo: '',
        }
    },
    methods: {
        // 获取订单详情
        getDetails() {
            let _token = this.$cookies.get('mcj_token')

            this.$axios
                .post('/api/order/detail', {
                    token: _token,
                    order: this.orderId,
                })
                .then((res) => {
                    console.log('details', res)
                    this.details = res.data.data
                    this.detailsInfo = res.data.data.detail
                })
        },
    },
    created() {
        // 获取订单ID
        this.orderId = this.$route.query.id
        console.log('当前订单id为：', this.orderId)

        this.getDetails()
    },
}
</script>

<style lang="scss" scoped>
.bg-box {
    background-color: #f8f8f8;
    min-height: 100%;
    padding-bottom: 50px;
}

.bgf {
    background-color: #fff;
}

.area-title {
    font-size: 18px;
    font-weight: bold;
    padding-left: 16px;
    margin: 24px 0 14px;
}

.page-title {
    height: 80px;
    line-height: 80px;
    background-color: #fff;
    font-size: 20px;
    font-weight: bold;
    text-align: center;
}

.info-list {
    background-color: #fff;
    position: relative;
    z-index: 1;
    padding: 14px 16px;
    margin-bottom: 12px;

    .list {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 10px;

        &:last-child {
            margin-bottom: 0;
        }

        .title {
            color: #878787;
            font-size: 14px;
        }
        .content {
            font-size: 16px;
            max-width: 66%;
        }
    }
}
</style>