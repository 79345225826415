<template>
    <div id="app">
        <!-- 视图窗口 -->
        <router-view />
    </div>
</template>

<script>
import { mapState } from 'vuex'

export default {
    data() {
        return {
            user: '',
            isFromWechat: false,
        }
    },
    methods: {
        // logOut() {
        //     let user = localStorage.getItem('user')
        //     let token = localStorage.getItem('_token')
        //     if (user && token) {
        //         localStorage.removeItem('user')
        //         localStorage.removeItem('_token')
        //         window.location.reload() // 刷新页面
        //         this.$router.push('/home') // 跳转至首页
        //     } else {
        //         return false
        //     }
        // },
    },
    computed: {
        ...mapState('user', ['username']),
    },
    created() {
        // 检查用户是否从微信打开
        if (/MicroMessenger/i.test(navigator.userAgent)) {
            console.log('当前是微信网页')
            this.$store.commit('setIsFromWeChat', true) // 存储到 Vuex
        } else {
            console.log('当前是浏览器')
        }
    },
    mounted() {
        // console.log('Username:', this.username)
        this.user = this.username
    },
    // mounted() {
    //     let user = localStorage.getItem('user')
    //     this.user = user
    // },
}
</script>

<style lang="scss">
html,body,#app{
    height: 100%;
}

.linear-bg {
    // background-image: linear-gradient(#4ea1ff, #0e70ed, #1d77ee);
    // background-color: #f8b62b !important;
    color: #fff !important;
}

.btn-primary {
    color: #fff !important;
}

.bdb {
    padding-bottom: 16px;
    border-bottom: 1px solid #f8b62b;
}

.nav-link.active {
    border-bottom-color: transparent !important;
}
</style>
