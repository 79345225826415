<template>
    <div class="bg-box">
        <div class="justify-content-center align-item-center">
            <div class>
                <div class="title-box">
                    <h2 class="text-center fw-bold">账号登录</h2>
                    <h6 class="text-center fw-bold mt-3 mb-0">Hi用户,欢迎您回来呀!</h6>
                </div>
                <form class="form-box">
                    <div class="inp-box">
                        <input type="tel" class="form-control inp" id="phoneNumber" placeholder="请输入手机号" v-model="tel" />
                    </div>
                    <div class="inp-box">
                        <div class="d-flex align-items-center">
                            <input type="tel" class="form-control inp" id="verificationCode" placeholder="请输入验证码" v-model="code" />
                            <button type="button" class="btn btn-outline-primary flex-shrink-0 code-btn" id="sendCodeBtn" @click="getcode" :disabled="btndisabled">{{btnstr}}</button>
                        </div>
                    </div>
                    <button type="submit" class="btn btn-primary linear-bg w-100 login-btn" @click.prevent="login">登录</button>
                </form>
                <div>
                    <div class="form-check d-flex justify-content-center align-items-center p-0 m-0">
                        <input class="form-check-input mt-0 ms-0" type="checkbox" id="flexCheckDefault1" v-model="ckOne" />
                        <label class="form-check-label label-text d-flex align-items-center" for="flexCheckDefault1">
                            登录即视为同意
                            <a href="#" @click.prevent="goPage('agreement')">《服务协议》</a>和
                            <a href="#" @click.prevent="goPage('statement')">《隐私政策》</a>
                        </label>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            tel: '',
            code: '',
            btndisabled: false,
            btnstr: '获取验证码',
            ckOne: false,
        }
    },
    methods: {
        // 获取验证码
        getcode() {
            let reg_phobe = /^1[13456789]\d{9}$/
            //验证手机号码是否合法
            if (this.tel == '') {
                alert('电话号码不能为空')
                return
            }
            if (!reg_phobe.test(this.tel)) {
                alert('手机号码不合法')
                return
            }
            //请求发送验证码
            this.$axios
                .get('/api/sendCode', {
                    params: {
                        phone: this.tel,
                    },
                })
                .then((res) => {
                    console.log('res', res)
                    if (res.data.code == 200) {
                        console.log(res.data.msg)
                    }
                })
                .catch((err) => {
                    console.log('err', err)
                })
            console.log('发送请求')
            //判断用户输入的验证码和请求的验证码是否一致
            //同时处理获取验证码这一块
            this.timewait(60)
            this.btndisabled = true
        },
        // 获取验证码间隔时间
        timewait(t) {
            let _this = this
            setTimeout(function () {
                if (t >= 0) {
                    _this.btnstr = t + '秒后点击'
                    t--
                    _this.timewait(t)
                } else {
                    _this.btnstr = '发送验证码'
                    t = 60
                    _this.btndisabled = false
                }
            }, 1000)
        },
        // 页面跳转
        goPage(path, i) {
            if (i) {
                this.$router.push({ path: `/${path}`, query: { id: i } })
                return
            }
            this.$router.push(`/${path}`)
        },
        // 登录
        login() {
            let reg_phobe = /^1[13456789]\d{9}$/
            //验证手机号码是否合法
            if (this.tel == '') {
                alert('电话号码不能为空')
                return
            }
            if (!reg_phobe.test(this.tel)) {
                alert('手机号码不合法')
                return
            }
            if (this.code == '') {
                alert('验证码不能为空')
                return
            }
            if (!this.ckOne) {
                alert('请勾选服务协议与隐私政策同意框')
                return
            }
            console.log('登录')
            //发送验证码登录的请求
            this.$axios
                .post('/api/login', {
                    phone: this.tel,
                    code: this.code,
                })
                .then((res) => {
                    console.log('res', res)
                    if (res.data.code == 200) {
                        console.log(res.data.msg)
                        this.$cookies.set('mcj_token', res.data.data.token);
                    }
                    let _token = this.$cookies.get('mcj_token')
                    //判断是否存在登录信息
                    if (typeof _token === 'string') {
                        localStorage.setItem('mcj_user', this.tel)
                        this.$store.dispatch('user/setUsernum', this.tel)
                        this.$router.push('/myorder')
                    } else {
                        //不存在或者token错误
                        alert('登录失败，请重新登录')
                    }
                })
                .catch((err) => {
                    console.log('err', err)
                })
        },
    },
}
</script>

<style lang="scss" scoped>
.bg-box {
    background-color: #f8f8f8;
    min-height: 100%;
    padding: 0 22px;
}

.title-box {
    padding-top: 90px;
}

.form-box {
    margin: 50px 0 24px;
}

.inp-box {
    background-color: #fff;
    border-radius: 10px;
    overflow: hidden;
    margin-bottom: 19px;
}

.inp {
    border: none;
    background-color: #fff;
    padding: 20px 0 20px 20px;
    font-size: 14px;

    &::placeholder {
        font-size: 14px;
        color: #919191;
    }

    &:focus {
        outline: none;
        box-shadow: none;
    }

    &:-webkit-autofill {
        background: transparent;
        transition: background-color 50000s ease-in-out 0s;
        -webkit-text-fill-color: unset;
    }
}

.code-btn,
.login-btn {
    border-radius: 22px;
}

.code-btn {
    padding: 6px 14px;
    font-size: 12px;
    margin-right: 16px;
}

.login-btn {
    padding: 0.7rem 0;
    border-radius: 30px;
    letter-spacing: 3px;
    font-size: 1.2rem;
    margin-top: 14px;
}

.label-text {
    font-size: 14px;
    color: #888888;

    a {
        color: #353535;
        text-decoration: none;
    }
}

.form-check{
    min-height: 0 !important;
}

.form-check-input {
    border-radius: 50% !important;
    margin-right: 9px;
}
</style>