<template>
    <div>
        <!-- 视图窗口 -->
        <router-view />
    </div>
</template>

<script>
export default {
    // //组件路由守卫判断用户是否登录
    // beforeRouteEnter(to, from, next) {
    //     // 获取本地token
    // let _token = this.$cookies.get('mcj_token')
    //     //判断是否存在登录信息
    //     if (!_token) {
    //         //不存在则跳转登录页
    //         next({ path: '/login' })
    //     } else {
    //         next();
    //     }
    // },
}
</script>

<style lang="scss" scoped>
</style>