<template>
    <div class="bg-box">
        <div class="page-title bgf">申请备案</div>
        <div class="service-selection bgf">
            <div class="area-title">服务选择</div>
            <div class="list-box">
                <div :class="['service',radioVal == index + 1 ? 'active':'']" v-for="item,index in set_meal" :key="index" @click="selectMeal(index + 1,item.price)">
                    <div class="title">{{item.name}}</div>
                    <div class="vice-title" v-if="index == 0">指导用户填写备案材料</div>
                    <div class="vice-title" v-if="index == 1">1v1咨询，极速提交</div>
                    <div class="price">￥{{[item.price * 100] / 100}}/次</div>
                    <div class="ps-title">99%用户选择</div>
                    <img src="@/assets/img/else/active.png" alt class="ps-active" />
                </div>
            </div>
        </div>
        <div class="created-beian">
            <div class="area-title bgf">创建备案</div>
            <form :class="['formorder','form','needs-validation',fromClass ? 'was-validated':'']" @submit.prevent="submitForm" novalidate>
                <div class="info-area bgf">
                    <div class="info-list">
                        <label class="form-label lab">用户名称</label>
                        <div>{{user}}</div>
                    </div>
                    <div class="info-list">
                        <label class="form-label lab">性质</label>
                        <div>
                            <select class="form-select inp" name="性质" v-model="type">
                                <option v-for="item in typeList" :key="item.id" :value="item.id">{{item.name}}</option>
                            </select>
                        </div>
                    </div>
                    <div class="info-list">
                        <label class="form-label lab">
                            主办单位/个人名称
                            <span class="text-danger">*</span>
                        </label>
                        <input type="text" class="form-control verify_input inp" required name="主办单位或主办人名称" v-model="userName" placeholder="请填写主办单位/个人名称" />
                    </div>
                    <div class="info-list">
                        <label class="form-label lab">
                            小程序名称
                            <span class="text-danger">*</span>
                        </label>
                        <input type="text" class="form-control verify_input inp" required name="小程序名称" v-model="appName" placeholder="请填写小程序名称" />
                    </div>
                    <div class="info-list">
                        <label class="form-label lab">推荐码</label>
                        <input type="text" class="form-control inp" placeholder="请填写推荐码" v-model="code" />
                    </div>
                    <div class="info-list">
                        <label class="form-label lab">优惠券</label>
                        <div>
                            <select class="form-select inp" v-model="coupin" @change="selectCoupin()">
                                <option v-for="item in coupinList" :key="item.id" :value="item.detail.id">无门槛券--抵扣{{(item.detail.amount * 100) / 100}}元</option>
                            </select>
                        </div>
                    </div>
                    <div class="tips">
                        <span>温馨提示：</span>帐号名称长度为4-30个字符，一个中文字等于2个字符。请保持小程序名称的独特性以免与其他已注册小程序冲突。
                    </div>
                </div>
                <div class="reviewing bgf">
                    <div class="form-check">
                        <input class="form-check-input" type="checkbox" id="flexCheckDefault1" v-model="ckOne" />
                        <label class="form-check-label reviewing-lab" for="flexCheckDefault1">
                            我已阅读并同意
                            <a href="#" @click.prevent="goPage('agreement')">《仓颉科技代理备案服务协议》</a>
                            <a href="#" @click.prevent="goPage('statement')">《仓颉科技代理备案服务信息收集及使用声明》</a>及同时授权本账号相关信息给仓颉科技用于开展服务
                        </label>
                    </div>
                </div>
                <div class="price-area bgf">
                    <div class="price">
                        ￥
                        <span>{{sel_price}}</span>
                    </div>
                    <button type="submit" :class="ckOne == true ? 'trueBg':''">立即支付</button>
                </div>
            </form>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            fromClass: false, //from表单验证样式
            typeList: [], //性质列表
            type: 1, //默认性质类型
            userName: '', //用户名
            appName: '', //小程序名称
            code: '', //推荐码
            set_meal: [], //现有套餐
            radioVal: 1, //默认套餐
            ckOne: false, //是否同意声明
            inputs: [], //需要判断是否为空的input框
            user: '',
            sel_price: '', //当前订单的价格
            count_price: '', //当前订单的价格(计算用)
            coupin: '', //优惠券ID
            coupinList: [], //优惠券列表
        }
    },
    methods: {
        // 选择套餐
        selectMeal(i, price) {
            this.radioVal = i
            this.sel_price = (price * 100) / 100
            // 计算用价格
            this.count_price = (this.set_meal[this.radioVal - 1].price * 100) / 100
            // 每次选择套餐后重新调用计算,得选择优惠券才执行
            if (this.coupin) {
                this.selectCoupin()
            }
        },
        // 提交表单
        submitForm() {
            // 调用检查表单的函数
            const emptyInput = this.checkEmptyInput()

            // 如果函数返回，则平移至为空的input框前
            if (emptyInput) {
                this.fromClass = true //给bs表单加上提醒样式的类名
                emptyInput.scrollIntoView({
                    behavior: 'smooth', //平移
                    block: 'center', //居中显示
                })
            } else {
                // 判断是否同意声明和协议
                if (this.ckOne) {
                    // 下一步操作
                    // 提交表单逻辑
                    this.fromClass = false //清除bs表单的提醒样式
                    // 创建订单
                    let _token = this.$cookies.get('mcj_token')
                    this.$axios
                        .post('/api/order/add', {
                            token: _token,
                            type: parseInt(this.type),
                            subject: this.userName,
                            app: this.appName,
                            code: this.code,
                            goods: parseInt(this.radioVal),
                            coupon: this.coupin,
                        })
                        .then((res) => {
                            console.log('res', res)
                            if (res.data.code == 200) {
                                console.log(res.data)
                                localStorage.setItem('orderIds', res.data.data.id) // 订单id存储
                                this.$router.push({ name: 'payments', params: { id: res.data.data.id } })
                            }
                            if (res.data.code == 500) {
                                console.log(res.data.msg)
                            }
                        })
                } else {
                    alert('请全部勾选声明协议')
                    return
                }
            }
        },
        // 检查是否有空的input框
        checkEmptyInput() {
            // 遍历检查是否有空的输入框
            for (let i = 0; i < this.inputs.length; i++) {
                if (this.inputs[i].value === '') {
                    return this.inputs[i]
                }
            }

            return null // 如果没有空的输入框
        },
        // 获取优惠券
        getCoupin() {
            let _token = this.$cookies.get('mcj_token')

            this.$axios
                .post('/api/coupon/list', {
                    token: _token,
                })
                .then((res) => {
                    if (res.data.code == 200) {
                        this.coupinList = res.data.data
                        console.log('this.coupinList', this.coupinList)
                    }
                    if (res.data.code == 500) {
                        console.log(res.data.msg)
                    }
                })
        },
        // 选择优惠券
        selectCoupin() {
            // 获取优惠券ID
            console.log('this.coupin', this.coupin)
            // 获取优惠券金额
            let selectedOption = this.coupinList.find((option) => option.detail.id === this.coupin)
            console.log('优惠券金额', selectedOption.detail.amount)
            let thisCoupin = Number(selectedOption.detail.amount)
            // 计算优惠后的价格
            let count_price = this.count_price
            let difference = count_price - thisCoupin
            let roundedResult = Math.ceil(difference * 100) / 100 // 向上取整保留两位小数
            roundedResult = roundedResult.toFixed(2) // 保留两位小数
            // 如果小数点后两位都是0，则不显示
            this.sel_price = Number(roundedResult) === parseInt(roundedResult) ? parseInt(roundedResult) : roundedResult
        },
        // 页面跳转
        goPage(path, i) {
            if (i) {
                this.$router.push({ path: `/${path}`, query: { id: i } })
                return
            }
            this.$router.push(`/${path}`)
        },
    },
    // 钩子
    mounted() {
        //dom获取所有input框
        this.inputs = document.querySelectorAll('.verify_input')
    },
    created() {
        // 企业性质
        const request1 = this.$axios.get('/api/type/list')

        // 商品列表
        const request2 = this.$axios.get('/api/goods/list')

        // 同时调用两个请求
        Promise.all([request1, request2]).then((res) => {
            // 两个请求现在都执行完成
            console.log('res', res)

            this.typeList = res[0].data.data
            this.set_meal = res[1].data.data
            this.sel_price = (res[1].data.data[0].price * 100) / 100
            this.count_price = (res[1].data.data[this.radioVal - 1].price * 100) / 100
        })

        // 获取优惠券
        this.getCoupin()

        let user = localStorage.getItem('mcj_user')
        this.user = user
    },
}
</script>

<style lang="scss" scoped>
$primary-color: #70c6c0;
$assist-color: #47958f;

.bg-box {
    background-color: #f8f8f8;
    min-height: 100%;
}

.was-validated .form-control:valid,
.was-validated .form-select:valid {
    border-color: var(--bs-border-color);
}
.was-validated .form-control:valid {
    background-image: none;
}
.was-validated .form-select:valid:not([multiple]):not([size]) {
    --bs-form-select-bg-icon: none;
}

.btn-outline-primary:hover {
    background: #eee;
    color: #000;
}

.line2 {
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    overflow: hidden;
}

.bgf {
    background-color: #fff;
}

.page-title {
    font-size: 20px;
    font-weight: bold;
    text-align: center;
    padding: 20px;
}

.service-selection {
    margin: 10px 0;
    padding: 20px 16px;

    .area-title {
        font-size: 18px;
        font-weight: bold;
        margin-bottom: 12px;
    }

    .list-box {
        display: flex;
        align-items: center;
        justify-content: space-between;

        .service {
            border: 1px solid #d9d9d9;
            border-radius: 10px;
            position: relative;
            padding: 20px 18px 14px;
            box-sizing: border-box;
            flex-basis: 47%;
            height: 150px;

            &.active {
                border-color: $primary-color;

                .title {
                    color: $primary-color;
                }
                .vice-title {
                    color: $primary-color;
                }
                .price {
                    color: $primary-color;
                }
                .ps-active {
                    display: block;
                }
            }

            &:last-child {
                &.active {
                    .ps-title {
                        display: block;
                    }
                }
            }

            .title {
                font-weight: bold;
                font-size: 18px;
            }
            .vice-title {
                color: #696969;
                font-size: 12px;
                margin: 6px 0 12px;
            }
            .price {
                color: #454545;
                font-weight: bold;
            }
            .ps-title {
                position: absolute;
                left: -1px;
                top: -12px;
                background-color: $primary-color;
                color: #fff;
                font-size: 14px;
                padding: 2px 8px;
                border-radius: 10px 0 10px 0;
                display: none;
            }
            .ps-active {
                position: absolute;
                right: -17px;
                bottom: -18px;
                display: none;
            }
        }
    }
}

.created-beian {
    .area-title {
        font-size: 18px;
        font-weight: bold;
        padding: 20px 0 20px 16px;
    }

    .info-area {
        padding: 0 16px 16px;

        .info-list {
            margin-bottom: 18px;
        }
    }

    .inp {
        border: none;
        font-size: 14px;
        background-color: #f8f8f8;
        padding: 16px;

        &::placeholder {
            color: #919191;
            font-size: 14px;
        }

        &:focus {
            outline: none;
            box-shadow: none;
        }
    }

    .lab {
        font-weight: bold;
        margin-bottom: 14px;
    }

    .tips {
        color: #696969;
        font-size: 14px;

        span {
            color: #3b3b3b;
            font-weight: bold;
        }
    }

    .reviewing {
        border-top: 1px solid #f8f8f8;
        padding: 16px;
    }

    .reviewing-lab {
        font-size: 14px;
        color: #484848 !important;
        line-height: 22px;

        a {
            text-decoration: none;
        }
    }

    .price-area {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 16px;
        margin-top: 60px;

        .price {
            font-size: 18px;
            color: $primary-color;
            font-weight: bold;

            span {
                font-size: 26px;
                margin-left: -5px;
            }
        }

        button {
            background-color: #afdfdc;
            border: none;
            color: #fff;
            font-weight: bold;
            font-size: 18px;
            letter-spacing: 1px;
            padding: 12px 30px;

            &.trueBg {
                background-color: $primary-color;
            }
        }
    }
}
</style>